import React from "react";
import { Board } from "../types";
import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import {
  Typography,
  Grid,
  Box,
  Button,
  LinearProgress,
} from "@material-ui/core";
import useDesktop from "./useDesktop";
import usePosts from "../store/usePosts";
import EventPhotoGridItem from "./EventPhotoGridItem";
const useStyles = makeStyles((theme) => {
  return {
    container: {
      flex: 1,
      paddingBottom: theme.spacing(5),
    },
    titleContainer: {
      borderBottom: `1px solid ${grey[400]}`,
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
    photoGrid: {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
      [theme.breakpoints.up("md")]: {
        paddingTop: theme.spacing(3),
      },
    },
  };
});

export default function RouteBoardEvent({ board: b }: { board: Board }) {
  const [isDesktop] = useDesktop();
  const classes = useStyles();
  const [isClosed, setClosed] = React.useState(true);
  const [posts] = usePosts({ board_id: b.id, isClosed });
  if (posts === undefined) {
    return <LinearProgress />;
  }
  return (
    <section className={classes.container}>
      <Grid container alignItems="center" className={classes.titleContainer}>
        <Button onClick={() => setClosed(false)} style={{ padding: 0 }}>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h4"
              color={isClosed ? "textSecondary" : "textPrimary"}
            >
              진행 예정 모임
            </Typography>
            <Box mr={1} />
            <Typography
              variant="h4"
              color={isClosed ? "textSecondary" : "primary"}
            >
              {b?.count_open || 0}
            </Typography>
          </Box>
        </Button>
        <Box mr={2} />
        <Button onClick={() => setClosed(true)} style={{ padding: 0 }}>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h4"
              color={isClosed ? "textPrimary" : "textSecondary"}
            >
              완료 된 모임
            </Typography>
            <Box mr={1} />
            <Typography
              variant="h4"
              color={isClosed ? "primary" : "textSecondary"}
            >
              {b?.count_closed || 0}
            </Typography>
          </Box>
        </Button>
      </Grid>
      <Grid container spacing={isDesktop ? 3 : 2} className={classes.photoGrid}>
        {posts
          .filter((a) => (typeof a.closed_at === "string") === isClosed)
          .map((p) => (
            <EventPhotoGridItem p={p} md={3} xs={6} key={p.id} />
          ))}
      </Grid>
    </section>
  );
}
